import { loadingAtom, authAtom, formPayload } from '@RecoilGlobal'
import { useLocation } from 'react-router-dom'
import { useRecoilCallback, useRecoilState, useSetRecoilState } from 'recoil'
import { signIn } from '@Utils/Api'
import { errorMessageAtom } from '@RecoilGlobal/form'

export const useLoginApi = (): { loading: boolean, postLogin: () => Promise<void> } => {
  const { pathname: location } = useLocation()
  const setErrorMessage = useSetRecoilState(errorMessageAtom(location))
  const [loading, setLoading] = useRecoilState(loadingAtom(location))

  const callback = useRecoilCallback(({ set, snapshot }) => async () => {
    setLoading(true)
    const form = await snapshot.getPromise(formPayload({ key: location }))
    try {
      const res = await signIn(form)
      set(authAtom, res)
    } catch {
      setErrorMessage(['Login failed, please check your username password.'])
    }
    setLoading(false)
  })

  return { loading, postLogin: callback }
}
