import { styled, Theme, useTheme } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { leftDrawerOpenedAtom } from './recoil'

const drawerWidth = 300
const StyledMain = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }: { theme: Theme, open: boolean }) => ({
  [theme.breakpoints.up('md')]: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
    minHeight: 'calc(100vh - 88px)',
    flexGrow: 1,
    padding: '20px',
    marginTop: '70px',
    // marginRight: '20px',

    ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginLeft: -(drawerWidth),
      width: `calc(100% - ${drawerWidth}px)`
    }),
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: `calc(100% - ${drawerWidth}px)`
    })
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginTop: '56px',
    py: 1,
    maxHeight: 'calc(100vh - 112px)',
    backgroundColor: theme.palette.background.default
  }
}))

const Main = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const theme = useTheme()
  const open = useRecoilValue(leftDrawerOpenedAtom)
  return (
    <StyledMain open={open} theme={theme}>
      {children}
    </StyledMain>
  )
}

export default Main
