import React from 'react'
import './index.css'
import {
  Route,
  Routes,
  Navigate
} from 'react-router-dom'
import Summary from './pages/summary/Summary'
import DailyReport from './pages/daily-report/DailyReport'
import { Login } from './pages/login'
import AuthenticatedRoute from '@Components/authenticated-route/AuthenticatedRoute'
// import { Dashboard } from './pages/dashboard/Dashboard'
import ChangePassword from './pages/user-profile/ChangePassword'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useRecoilValue } from 'recoil'
import { authParamsAtom } from '@RecoilGlobal/auth'
import { theme } from '@Utils/theme'
import { ThemeProvider, CssBaseline } from '@mui/material'
import { useMiddleware } from '@Hooks/useMiddleware'
import api from '@Utils/axios'

const Routing = (): JSX.Element => {
  useMiddleware()

  const { loggedIn, user } = useRecoilValue(authParamsAtom)

  if (user != null) {
    api.defaults.headers.common.Authorization =
      'Bearer ' + user.accessToken
    api.defaults.baseURL = process.env.REACT_APP_API
  } else {
    delete api.defaults.headers.common.Authorization
  }

  return (
    <Routes>
      <Route
        path='/'
        element={loggedIn ? <Navigate to='/summary' /> : <Navigate to='/login' />}
      />
      <Route
        path='/login'
        element={loggedIn ? <Navigate to='/summary' /> : <Login />}
      />
      <Route
        path='/summary'
        element={<AuthenticatedRoute><Summary /></AuthenticatedRoute>}
      />
      <Route
        path='/daily-report'
        element={<AuthenticatedRoute><DailyReport /></AuthenticatedRoute>}
      />
      <Route
        path='/user-profile/change-password'
        element={<AuthenticatedRoute><ChangePassword /></AuthenticatedRoute>}
      />
      <Route
        path='*'
        element={<Navigate to='/login' />}
      />
      {/* <Route
        path='/dashboard'
        element={<AuthenticatedRoute><Dashboard /></AuthenticatedRoute>}
      /> */}
    </Routes>
  )
}

export const App = (): JSX.Element => {
  return (
    <ThemeProvider theme={theme.customTheme}>
      <CssBaseline />
      <ToastContainer />

      <Routing />
    </ThemeProvider>
  )
}
