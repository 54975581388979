import React from 'react'
import {
  LinkProps,
  Link as RouterLink
} from 'react-router-dom'

export const renderLink = (path: string): React.ForwardRefExoticComponent<Omit<LinkProps, 'to'> & React.RefAttributes<HTMLAnchorElement>> =>
  React.forwardRef<HTMLAnchorElement, Omit<LinkProps, 'to'>>(function Link (
    itemProps,
    ref
  ) {
    return <RouterLink to={path} ref={ref} {...itemProps} role={undefined} />
  })
