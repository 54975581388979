import React from 'react'
import { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { Card, Stack } from '@mui/material'
import { InputText } from '@Components/FormInputs'
import { useLocation } from 'react-router'
import { InputID } from '@Components/FormInputs/interface'
import { z } from 'zod'
import { Z_STRING_FIELD_REQUIRED } from '@Utils/zod'
import ButtonSubmit from '@Components/FormInputs/ButtonSubmit'
import { useRecoilCallback, useRecoilState, useResetRecoilState } from 'recoil'
import { formPayload } from '@RecoilGlobal/form'
import { loadingAtom } from '@RecoilGlobal/api'
import api from '@Utils/axios'

const formSchema = z.object({
  OldPassword: Z_STRING_FIELD_REQUIRED,
  NewPassword: Z_STRING_FIELD_REQUIRED,
  ConfirmPassword: Z_STRING_FIELD_REQUIRED
}).superRefine((data, ctx) => {
  if ((data.NewPassword !== data.ConfirmPassword)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Password do not match',
      fatal: true,
      path: ['ConfirmPassword']
    })
  }
})

const usePostChangePassword = (location: string): { postChangePass: () => Promise<void>, loading: boolean } => {
  const [loading, setLoading] = useRecoilState(loadingAtom('usePostChangePassword'))
  const resetForm = useResetRecoilState(formPayload({ key: location }))
  const cb = useRecoilCallback(({ snapshot }) => async () => {
    setLoading(true)
    const data = await snapshot.getPromise(formPayload({ key: location }))
    try {
      await api({
        url: '/agent/updatepassword',
        method: 'POST',
        data
      })
      toast.success('Password has been change successfully')
      resetForm()
    } catch (e) {
      if (e instanceof AxiosError) {
        toast.error(e?.response?.data.message)
      }
    }
    setLoading(false)
  })
  return { postChangePass: cb, loading }
}
const ChangePassword: React.FC = () => {
  const { pathname: location } = useLocation()
  const { postChangePass, loading } = usePostChangePassword(location)

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    postChangePass().finally(() => {})
  }
  return (
    <Stack justifyContent='center' alignItems='center' width='100%' height='80%'>
      <Card
        sx={{
          width: '400px',
          p: 4
        }}
      >
        <Stack direction='column' component='form' onSubmit={onSubmit} spacing={2}>
          <InputText
            location={location}
            id={InputID.OldPassword}
            externalLabel='Old Password'
            required
            type='password'
            placeholder='Enter Old Password'
            validation={formSchema}
          />
          <InputText
            location={location}
            id={InputID.NewPassword}
            externalLabel='New Password'
            required
            type='password'
            placeholder='Enter New Password'
            validation={formSchema}
          />
          <InputText
            location={location}
            id={InputID.ConfirmPassword}
            externalLabel='Confirm Password'
            required
            type='password'
            placeholder='Enter Confirm Password'
            validation={formSchema}
          />
          <ButtonSubmit
            loading={loading}
            location={location}
            validation={formSchema}
          />
        </Stack>
      </Card>
    </Stack>
  )
}

export default ChangePassword
