import { Autocomplete, FormControl, OutlinedInputProps, Popper, InputLabel } from '@mui/material'
import React, { useState } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'
import { IInputProps, Options } from './interface'
import { useSetForm } from '@Hooks'
import { formPayload, inputAtom } from '@RecoilGlobal'
import { ZodError } from 'zod'
import { handleHelperText, StyledTextField } from './utils'
import { externalLabelAtom, triggerValidationAtom } from '@RecoilGlobal/form'
import { useStateChangeEffect } from '@Hooks/useStateChangeEffect'

interface IInputSelect extends IInputProps {
  inputProps?: Partial<OutlinedInputProps>
  color?: 'paper' | 'default'
  prefix?: boolean
  canClear?: boolean
  options: Options[]
}

const InputSelect = ({
  options,
  label,
  id,
  location,
  disabled,
  readOnly,
  inputProps,
  // prefix,
  canClear,
  externalLabel: pExternalLabel,
  placeholder,
  validation,
  required = false,
  defaultValue = '',
  // color = 'paper',
  borderRadius = '4px',
  helperText,
  onChange = async () => {}
}: IInputSelect): JSX.Element => {
  const value = useRecoilValue(inputAtom({ location, id, defaultValue })) as number
  const [errorMessage, setErrorMessage] = useState<string[] | null>(null)
  const inputValue = options.find(v => v.value === value) ?? null
  const isReadonlyOrDisabled = Boolean(readOnly) || Boolean(disabled)
  const setPayload = useSetForm({ location, id })
  const externalLabel = useRecoilValue(externalLabelAtom({ defaultValue: pExternalLabel, id, location }))
  const triggerValidation = useRecoilValue(triggerValidationAtom(location))

  useStateChangeEffect(() => {
    validationCB().finally(() => { })
  }, [triggerValidation])

  const validationCB = useRecoilCallback(({ snapshot }) => async () => {
    const form = await snapshot.getPromise(formPayload({ key: location }))
    try {
      validation?.parse(form)
      setErrorMessage(null)
    } catch (e) {
      if (!(e instanceof ZodError)) return
      const fieldError = e.flatten().fieldErrors[id]
      if (fieldError != null) {
        setErrorMessage(fieldError)
      } else {
        setErrorMessage(null)
      }
    }
  }, [])

  const handleOnChange = (e: React.SyntheticEvent<Element, Event>, option: Options | null): void => {
    e.preventDefault()
    const newValue = option != null ? option.value : null
    setPayload(newValue)
    onChange(id, newValue).finally(() => {})
    if (validation == null) return
    if (errorMessage != null) {
      validationCB().finally(() => {})
    }
  }

  const handleBlur: React.FocusEventHandler<HTMLDivElement> = () => {
    if (validation == null) return
    validationCB().finally(() => { })
  }

  return (
    <>
      {externalLabel != null &&
        <InputLabel
          sx={theme => ({
            textAlign: 'left',
            px: 1,
            color: errorMessage != null ? theme.palette.error.light : theme.palette.text.secondary
          })}
          htmlFor={id}
        >
          <strong>{externalLabel} {required && '*'}</strong>
        </InputLabel>}
      <FormControl
        error={errorMessage != null}
        sx={{
          width: '100%',
          borderRadius
        }}
      >
        <Autocomplete
          sx={{ display: 'block' }}
          fullWidth
          blurOnSelect
          disableClearable={canClear != null ? !canClear : true}
          options={options}
          getOptionLabel={(option) => option.label}
          value={inputValue}
          onChange={handleOnChange}
          onBlur={handleBlur}
          readOnly={readOnly}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              label={label}
              size='small'
              variant='outlined'
              error={errorMessage != null}
              sx={{
                overflow: 'visible',
                minHeight: '2.5em'
                // minWidth: 0
              }}
              helperText={handleHelperText({ errorMessage, helperText })}
              InputProps={{
                ...params.InputProps,
                ...inputProps,
                placeholder,
                sx: theme => ({
                  height: '56px',
                  borderRadius,
                  whiteSpace: 'nowrap',
                  backgroundColor: isReadonlyOrDisabled ? theme.palette.background.default : theme.palette.background.paper,
                  boxShadow: theme.shadows[2]
                })
              }}
              FormHelperTextProps={{
                sx: {
                  position: 'relative',
                  left: -10,
                  display: 'flex',
                  flexDirection: 'column'
                }
              }}
            />
          )}
          PopperComponent={(props) => (
            <Popper {...props} sx={{ width: 'fit-content' }} />
          )}
        />
      </FormControl>
    </>
  )
}

export default InputSelect
