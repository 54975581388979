import React from 'react'
import { Merchant, allCurrencies } from '../../Types'
import { Grid } from '@mui/material'
import InputSelect from '@Components/FormInputs/InputSelect'
import { InputID } from '@Components/FormInputs/interface'
import PresetDateRangePicker from '@Components/preset-date-range-picker/PresetDateRangePicker'
import { useSetRecoilState } from 'recoil'
import { payload } from '@RecoilGlobal/form'
import { useOnMountLayoutEffect } from '@Hooks'
import ButtonSubmit from '@Components/FormInputs/ButtonSubmit'
import moment from 'moment'

interface CommandsBarProps {
  merchants: Merchant[]
  location: string
  onSearch: () => void
}
const CommandsBar = ({ merchants, location, onSearch }: CommandsBarProps): JSX.Element => {
  const setPayload = useSetRecoilState(payload({ location }))
  useOnMountLayoutEffect(() => {
    setPayload({
      startDate: moment(),
      endDate: moment(),
      currency: 1,
      merchantId: -1
    })
  })
  const merchantOptions = [
    { value: -1, label: 'All' },
    ...merchants.map(item => ({
      value: item.id, label: item.name
    }))
  ]

  const currencyList = allCurrencies.map(x => {
    return { value: x.id, label: x.name }
  })

  function onDatesChange ({ startDate, endDate }: { startDate: null | moment.Moment, endDate: null | moment.Moment }): void {
    setPayload({ startDate, endDate })
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    onSearch()
  }
  return (
    <Grid component='form' container spacing={2} onSubmit={handleSubmit}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs='auto'>
            <PresetDateRangePicker
              location={location}
              onDatesChange={onDatesChange}
              showClearDates={false}
              numberOfMonths={2}
              customPresets={['Today', 'Current Month', 'Last Month']}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <InputSelect
              id={InputID.currency}
              location={location}
              options={currencyList}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <InputSelect
              id={InputID.merchantId}
              location={location}
              options={merchantOptions}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} lg='auto'>
            <ButtonSubmit
              location={location}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CommandsBar
