import { useResetRecoilState } from 'recoil'
import { authAtom } from '@RecoilGlobal/auth'

export const useLogout = (): (() => void) => {
  const reset = useResetRecoilState(authAtom)
  const logout = (): void => {
    reset()
    window.location.reload()
  }
  return logout
}
