// for api uses
import { atomFamily, RecoilState } from 'recoil'
import { PostSearchSuccess } from './interface'

export const loadingAtom = atomFamily({
  key: 'loading',
  default: false
})
// eslint-disable-next-line
const _searchResponseAtom = atomFamily<PostSearchSuccess<any>, string>({
  key: 'transactionSearchResponse',
  default: {
    data: [],
    total: 0
  }

})

export function searchResponseAtom<T> (id: string): RecoilState<PostSearchSuccess<T>> {
  return _searchResponseAtom(id)
}
