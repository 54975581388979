import React from 'react'
import { useRecoilState } from 'recoil'
import { openProfileAtom } from '../recoil'
import { Box, List, ListItemButton, ListItemIcon, ListItemText, Paper, Typography, Popover } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import PasswordIcon from '@mui/icons-material/Password'
import { useNavigate } from 'react-router'
import { useLogout } from '@Hooks/useLogout'

interface IProfilePopover {
  anchorRef: React.MutableRefObject<HTMLDivElement | null>
}

const SettingsContent: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const navigate = useNavigate()
  const logout = useLogout()
  const handleChangePass = (): void => {
    onClose()
    navigate('/user-profile/change-password')
  }
  return (
    <Paper>
      {/* <Typography variant='h6' sx={theme => ({ textAlign: 'center', p: 1, backgroundColor: theme.palette.background.default })}>{user?.username}</Typography> */}
      {/* <Divider /> */}
      <Box sx={{ p: 1 }}>
        <List
          component='nav'
          sx={theme => ({
            width: '100%',
            maxWidth: 250,
            minWidth: 200,
            backgroundColor: theme.palette.background.paper,
            borderRadius: '10px'
          })}
        >
          <ListItemButton
            sx={{ borderRadius: '10px' }}
            onClick={handleChangePass}
          >
            <ListItemIcon>
              <PasswordIcon />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant='body2'>Change Password</Typography>}
            />
          </ListItemButton>
          <ListItemButton
            sx={{ borderRadius: '10px' }}
            onClick={logout}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant='body2'>Sign Out</Typography>}
            />
          </ListItemButton>
        </List>
      </Box>
    </Paper>
  )
}

const SettingsPopover: React.FC<IProfilePopover> = ({ anchorRef }) => {
  const [open, setOpen] = useRecoilState(openProfileAtom)

  const handleClose = (): void => {
    setOpen(false)
  }
  return (
    <Popover
      id='profileIcon'
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={open}
      anchorEl={anchorRef.current}
      onClose={handleClose}
    >
      <SettingsContent onClose={handleClose} />
    </Popover>
  )
}

export default SettingsPopover
