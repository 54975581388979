import { ButtonBase } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { theme } from '@Utils/theme'

/** Logo Section for GameWallet and SRPay */
const LogoSection = (): JSX.Element => {
  return (
    <ButtonBase disableRipple component={Link} to='/dashboard'>
      <img
        src={theme.logo}
        alt={`${theme.title} Logo`}
        height='50px'
      />
    </ButtonBase>
  )
}

export default LogoSection
