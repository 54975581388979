import ErrorFieldMessage from '@Components/error-field-message/ErrorFieldMessage'
import { Grid, Button, Paper, Box } from '@mui/material'
import CommandsBar from './CommandsBar'
import { DataTable } from '@Components/DataTable'
import { useLocation } from 'react-router'
import { fetchAllMerchants } from '@Utils/Api'
import { useAsync } from 'react-async-hook'
import { useRecoilState } from 'recoil'
import moment from 'moment'
import { TableColumns } from '@Components/DataTable/interface'
import { formatByCurrency } from '@Utils'
import { dataCollapseAtom } from '@Components/DataTable/CollapseItem'
import { useGetDailyTransaction, useGetSearchReport } from './api'
import { DailyTransactionResponse, DataResponse } from './interface'
import { useOnMountEffect } from '@Hooks'

const MoreColumn = ({ location, rowIdx, merchantId }: { location: string, rowIdx: number, merchantId: number }): JSX.Element => {
  const [collapseIn, setCollapseIn] = useRecoilState(dataCollapseAtom(`${location}${rowIdx}`))
  const { getDailyTransaction } = useGetDailyTransaction(location)

  const handleMore = (): void => {
    if (!collapseIn) {
      getDailyTransaction(merchantId).finally(() => {})
    }
    setCollapseIn(prev => !prev)
  }
  return (
    <Button color='primary' onClick={handleMore} sx={{ fontWeight: 600 }}>...</Button>
  )
}

const DailyReport: React.FC = () => {
  const { pathname: location } = useLocation()
  const getMerchants = useAsync(fetchAllMerchants, [])
  const { loading, searchReport } = useGetSearchReport(location)
  const merchants = getMerchants.loading
    ? []
    : (getMerchants.result != null)
        ? getMerchants.result.items
        : []

  const handleSearch = (): void => {
    searchReport().finally(() => {})
  }

  useOnMountEffect(() => {
    handleSearch()
  })

  const columns: Array<TableColumns<DataResponse>> = [
    {
      field: 'merchantId',
      headerName: 'ID'
    },
    {
      field: 'merchant',
      headerName: 'Merchant Name'
    },
    {
      field: 'currency',
      headerName: 'Currency',
      renderCell: ({ row }) => (
        row.merchantId != null ? row.currency : <b>Total:</b>
      )
    },
    {
      field: 'totalDeposit',
      headerName: 'Total Deposit',
      renderCell: ({ row }) => {
        const text = formatByCurrency(row.currency)(row.totalDeposit)
        return (
          row.merchantId != null ? text : <b>{text}</b>
        )
      }
    },
    {
      field: 'totalWithdrawal',
      headerName: 'Total Withdrawal',
      renderCell: ({ row }) => {
        const text = formatByCurrency(row.currency)(row.totalWithdrawal)
        return (
          row.merchantId != null ? text : <b>{text}</b>
        )
      }
    },
    {
      field: 'depositFee',
      headerName: 'Total Deposit Fee',
      renderCell: ({ row }) => {
        const text = formatByCurrency(row.currency)(row.depositFee)
        return (
          row.merchantId != null ? text : <b>{text}</b>
        )
      }
    },
    {
      field: 'withdrawalFee',
      headerName: 'Total Withdrawal Fee',
      renderCell: ({ row }) => {
        const text = formatByCurrency(row.currency)(row.withdrawalFee)
        return (
          row.merchantId != null ? text : <b>{text}</b>
        )
      }
    },
    {
      field: 'action',
      headerName: 'More',
      renderCell: ({ row, rowIdx }) => (
        row.merchantId != null ? <MoreColumn location={location} rowIdx={rowIdx} merchantId={row.merchantId} /> : <></>
      )
    }
  ]

  const collapseColumns: Array<TableColumns<DailyTransactionResponse>> = [
    {
      field: 'createdTime',
      headerName: 'Date',
      renderCell: ({ row }) => moment(row.createdTime).format(('MM-DD-YYYY'))
    },
    {
      field: 'merchant',
      headerName: 'Merchant Name'
    },
    {
      field: 'currency',
      headerName: 'Currency'
    },
    {
      field: 'totalDeposit',
      headerName: 'Total Deposit',
      renderCell: ({ row }) => formatByCurrency(row.currency)(row.totalDeposit)
    },
    {
      field: 'totalWithdrawal',
      headerName: 'Total Withdrawal',
      renderCell: ({ row }) => formatByCurrency(row.currency)(row.totalWithdrawal)
    },
    {
      field: 'depositFee',
      headerName: 'Total Deposit Fee',
      renderCell: ({ row }) => formatByCurrency(row.currency)(row.depositFee)
    },
    {
      field: 'withdrawalFee',
      headerName: 'Total Withdrawal Fee',
      renderCell: ({ row }) => formatByCurrency(row.currency)(row.withdrawalFee)
    }
  ]
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ErrorFieldMessage location={location} />
        </Grid>
        <Grid item xs={12}>
          <CommandsBar location={location} merchants={merchants} onSearch={handleSearch} />
        </Grid>
        <Grid item xs={12}>
          <Paper sx={theme => ({ bgcolor: theme.palette.background.paper })}>
            <DataTable
              loading={loading}
              columns={columns}
              location={location}
              collapse={(row) => (
                <Box my={2}>
                  <DataTable
                    onSearch={() => { }}
                    loading={false}
                    location={`${location}/${row.merchantId}`}
                    columns={collapseColumns}
                    disablePagination
                  />
                </Box>
              )}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default DailyReport
