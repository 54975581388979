import { TablePagination } from '@mui/material'
import { useRecoilState, useRecoilValue } from 'recoil'
import { paginationAtom, searchResponseAtom } from '@RecoilGlobal'
import { PaginationProps } from './interface'
import React from 'react'

const Pagination = <T,>({ location, onSearch = () => {} }: PaginationProps): JSX.Element => {
  const { total } = useRecoilValue(searchResponseAtom<T>(location))
  const [pagination, setPagination] = useRecoilState(paginationAtom(location))

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ): void => {
    setPagination(prev => ({
      ...prev,
      pageIndex: newPage + 1
    }))
    onSearch()
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setPagination({
      pageIndex: 1,
      pageSize: parseInt(event.target.value, 10)
    })
    onSearch()
  }

  return (
    <TablePagination
      component='div'
      count={total}
      page={pagination.pageIndex - 1}
      onPageChange={handleChangePage}
      rowsPerPage={pagination.pageSize}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
    // <MUIPagination
    //   color="primary"
    //   variant="outlined"
    //   shape="rounded"
    //   page={page + 1}
    //   count={pageCount}
    //   // @ts-expect-error
    //   renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
    //   onChange={(event: React.ChangeEvent<unknown>, value: number) =>
    //     apiRef.current.setPage(value - 1)
    //   }
    // />
  )
}

export default Pagination
