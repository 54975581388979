import { Grid, SxProps, Theme } from '@mui/material'
import React from 'react'

interface FilterContainerProps {
  onSubmit?: React.FormEventHandler<HTMLFormElement>
  children: React.ReactNode
  sx?: SxProps<Theme>
}
const FormContainer: React.FC<FilterContainerProps> = ({ onSubmit, children, sx }) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    if (onSubmit != null) {
      onSubmit(e)
    }
  }
  return (
    <Grid
      item xs={12} sx={theme => ({
        ...sx,
        [theme.breakpoints.down('md')]: {
          p: 2,
          ...sx != null ? sx[theme.breakpoints.down('md') as keyof SxProps<Theme>] : {}
        }
      })}
    >
      <Grid item xs={12}>
        <Grid container spacing={2} component='form' onSubmit={handleSubmit} p={1}>
          {children}
          <button type='submit' style={{ display: 'none' }} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FormContainer
