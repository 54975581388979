import React from 'react'
import Menu from './menu/Menu'
import Header from './header/Header'
import { AppBar as MUIAppBar, Box, CssBaseline, Toolbar, LinearProgress } from '@mui/material'
import Main from './Main'

export interface ILayoutState {
  isSideMenuOpen: boolean
}

const AppBar = (): JSX.Element => (
  <MUIAppBar
    enableColorOnDark
    color='inherit'
    elevation={6}
    sx={theme => ({
      bgcolor: theme.palette.primary.main
    })}
  >
    <Toolbar>
      <Header />
    </Toolbar>
  </MUIAppBar>
)

export const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Box sx={theme => ({ display: 'flex', bgcolor: theme.palette.background.paper })}>
      <CssBaseline />
      <AppBar />
      <Menu />
      <Main>
        <React.Suspense fallback={<LinearProgress />}>
          {children}
        </React.Suspense>
      </Main>
    </Box>
  )
}
