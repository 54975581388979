import { AxiosResponse } from 'axios'
import {
  PagedResult,
  TransactionBundle,
  Transaction,
  Merchant,
  MonthlySummary
} from '../Types'
import { IFormPayload } from '@RecoilGlobal/interface'
import api from './axios'
import { clientTimeZone } from '@Utils'

const API_BUNDLE_TRANSACTION_LIST = process.env
  .REACT_APP_MERCHANTS_BUNDLE_TRANSACTION_LIST as string
const API_BUNDLE_DETAIL_TRANSACTION_LIST = process.env
  .REACT_APP_MERCHANTS_BUNDLE_DETAIL_TRANSACTION_LIST as string
const API_SYSTEM_SUBMIT_OTP = process.env.REACT_APP_SYSTEM_SUBMIT_OTP as string
const API_SYSTEM_SUBMIT_CAPTCHA = process.env
  .REACT_APP_SYSTEM_SUBMIT_CAPTCHA as string

export const fetchTransactionBundles = <T,>(
  mapping?: (_: T[]) => TransactionBundle[]
) => async (
    merchant: string,
    cursor?: string
  ): Promise<PagedResult<TransactionBundle>> => {
    const url = cursor != null
      ? `${API_BUNDLE_TRANSACTION_LIST}?cursor=${cursor}&merchantId=${merchant}`
      : `${API_BUNDLE_TRANSACTION_LIST}?merchantId=${merchant}`
    const rsp = await api(url)
    const result = await rsp.data
    if (mapping != null) {
      return { ...result, items: mapping(result.items) }
    } else {
      return { ...result, items: result.items }
    }
  }

export const fetchTransactionBundleDetail = <T,>(
  mapping?: (_: T) => Transaction[]
) => async (
    merchant?: string,
    externalReference?: string
  ): Promise<PagedResult<Transaction>> => {
    const url = `${API_BUNDLE_DETAIL_TRANSACTION_LIST}?externalReference=${externalReference as string}&merchantId=${merchant as string}`
    const rsp = await api(url)
    const result = await rsp.data
    if (mapping != null) {
      return { ...result, items: mapping(result.items) }
    } else {
      return { ...result, items: result.items }
    }
  }

export const submitOtp = async (
  session: string,
  transactionId: string,
  otp: string
  // eslint-disable-next-line
): Promise<any> => {
  const rsp = await api.post(API_SYSTEM_SUBMIT_OTP, {
    session,
    transactionId,
    otp
  })
  return rsp.data
}

// eslint-disable-next-line
export const resolvedCaptcha = async (session: string, content: string): Promise<any> => {
  const rsp = await api.post(API_SYSTEM_SUBMIT_CAPTCHA, {
    session,
    content
  })
  return rsp.data
}

interface GetAgentSummariesArgs {
  startDate?: string
  endDate?: string
  currency?: number
  merchantId?: number
}

export const fetchAgentSummaries = async (
  args: GetAgentSummariesArgs
): Promise<MonthlySummary> => {
  const rsp = await api('/agenttransactions/GetDashboardTransactionSummary', {
    params: {
      currency: args.currency,
      startDate: args.startDate,
      endDate: args.endDate,
      merchantId: args.merchantId,
      clientTimeZone
    }
  })
  return rsp.data
}

export const fetchAllMerchants = async (): Promise<PagedResult<Merchant>> => {
  const rsp = await api('/merchant/getall', {
    params: {
      pageIndex: 1
    }
  })
  return { items: rsp.data }
}

interface PostLoginSuccess {
  authToken: string
}
type LoginResponse = PostLoginSuccess

export const signIn = async (credentials: IFormPayload): Promise<LoginResponse> => {
  const rsp: AxiosResponse<LoginResponse> = await api.post('/authentication/login', credentials)
  return rsp.data
}
