import { useLayoutEffect, useRef } from 'react'

const useOnMountLayoutEffect = (callback: () => void): void => {
  const renderRef = useRef(true)
  return useLayoutEffect(() => {
    if (renderRef.current) {
      callback()
      return () => {
        renderRef.current = false
      }
    }
  }, [callback])
}

export default useOnMountLayoutEffect
