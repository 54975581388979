import React, { useEffect, useState } from 'react'
import './styles.css'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'
import { Button, Paper } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { inputAtom } from '@RecoilGlobal/form'
import { InputID } from '@Components/FormInputs/interface'

interface IPresetDateRangePickerProps {
  onDatesChange: ({ startDate, endDate }: { startDate: null | moment.Moment, endDate: null | moment.Moment }) => void
  showClearDates: boolean
  customPresets?: string[] | undefined
  numberOfMonths?: number
  navDisable?: boolean | undefined
  location: string
}

type FocusedInputShape = 'startDate' | 'endDate'

interface Presets {
  text: string
  start: moment.Moment
  end: moment.Moment
}

const PresetDateRangePicker: React.FC<IPresetDateRangePickerProps> = (
  {
    onDatesChange,
    showClearDates,
    customPresets,
    numberOfMonths,
    navDisable = false,
    location
  }) => {
  const startDate = useRecoilValue(inputAtom({ location, id: InputID.startDate })) as moment.Moment
  const endDate = useRecoilValue(inputAtom({ location, id: InputID.endDate })) as moment.Moment

  const [focusedInput, setFocusedInput] = useState<null | FocusedInputShape>(null)
  const [selectedPreset, setSelectedPreset] = useState('')

  useEffect(() => {
    if (focusedInput == null && startDate != null && endDate == null) {
      const after30Days = moment(startDate).add(30, 'days')
      const endDate = after30Days.isAfter(moment()) ? moment() : after30Days
      onDatesChange({ startDate, endDate })
    }
  }, [startDate, endDate, focusedInput, onDatesChange])
  function onFocusChange (focus: null | FocusedInputShape): void {
    setFocusedInput(focus)
    if (focus === 'startDate') {
      onDatesChange({ startDate: null, endDate: null })
    } else if (focus == null && startDate == null && endDate == null) {
      onDatesChange({
        startDate: moment().subtract(30, 'days'),
        endDate: moment()
      })
    }
  }

  function renderSpecificMonths (day: moment.Moment): boolean {
    // const lastTwoMonthsEndDay = moment().subtract(2, 'months').endOf('month')
    // this will disabled the next month and the month prior the previous month
    return day.isSameOrAfter(moment())
  }

  function renderDatePresets (): string | JSX.Element {
    const today = moment()
    const currentMonth = moment().startOf('month')
    const lastMonthStartDay = moment().subtract(1, 'months').startOf('month')
    const lastMonthLastDay = moment().subtract(1, 'months').endOf('month')
    const startOfYear = moment().startOf('year')
    const lastYearStartDay = moment().subtract(1, 'years').startOf('year')
    const lastYearLastDay = moment().subtract(1, 'years').endOf('year')

    const presets: Presets[] = [
      {
        text: 'Today',
        start: today,
        end: today
      },
      {
        text: 'Current Month',
        start: currentMonth,
        end: today
      },
      {
        text: 'Last Month',
        start: lastMonthStartDay,
        end: lastMonthLastDay
      },
      {
        text: 'Current Year',
        start: startOfYear,
        end: today
      },
      {
        text: 'Last Year',
        start: lastYearStartDay,
        end: lastYearLastDay
      }
    ]

    const filteredPresets = presets.reduce((accu: Presets[], curr): Presets[] => {
      if (customPresets == null || !customPresets.includes(curr.text)) return accu
      return [...accu, curr]
    }, [])

    return (
      <ul className='presets-container'>
        {
          filteredPresets.map((presets: Presets, i: number) => {
            return (
              <li key={i}>
                <Button
                  onClick={() => {
                    onDatesChange({ startDate: presets.start, endDate: presets.end })
                    setSelectedPreset(presets.text)
                    setFocusedInput(null)
                  }}
                  className={selectedPreset === presets.text ? 'selected-preset' : ''}
                >
                  {presets.text}
                </Button>
              </li>
            )
          })
        }
      </ul>
    )
  }

  return (
    <Paper elevation={3} sx={{ height: '56px' }}>
      <DateRangePicker
        startDate={startDate}
        startDateId='startDateId'
        endDate={endDate}
        endDateId='endDateId'
        onDatesChange={onDatesChange}
        focusedInput={focusedInput}
        onFocusChange={onFocusChange}
        showClearDates={showClearDates}
        showDefaultInputIcon
        renderCalendarInfo={(customPresets != null) ? renderDatePresets : undefined}
        hideKeyboardShortcutsPanel
        isOutsideRange={renderSpecificMonths}
        onPrevMonthClick={() => false}
        numberOfMonths={numberOfMonths}
        navPrev={navDisable ? <span /> : undefined}
        navNext={navDisable ? <span /> : undefined}
        minimumNights={0}
        maxDate={moment(startDate).add(92, 'days')}
        isDayBlocked={day => Boolean(startDate) && moment(day).isAfter(moment(startDate).startOf('day').add(92, 'days'))}
      />
    </Paper>
  )
}

export default PresetDateRangePicker
