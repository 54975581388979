import axios from 'axios'
const { REACT_APP_API } = process.env

const baseURL = REACT_APP_API
const api = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json'
  }
})

export default api
