import { localStorageEffect } from '@Utils/recoil'
import { atom } from 'recoil'

export const openProfileAtom = atom<boolean>({
  key: 'openProfilePopover',
  default: false
})

export const leftDrawerOpenedAtom = atom<boolean>({
  key: 'sidebarOpen',
  default: true,
  effects: [
    localStorageEffect('sidebarOpen')
  ]

})
