export interface PagedResult<T> {
  cursor?: string
  items: T[]
  error?: string
}

export interface Currency {
  id: number
  name: string
}

export const VND: Currency = { id: 1, name: 'VND' }
export const THB: Currency = { id: 2, name: 'THB' }
export const RMB: Currency = { id: 3, name: 'RMB' }
export const IDR: Currency = { id: 4, name: 'IDR' }
export const MYR: Currency = { id: 5, name: 'MYR' }
export const KRW: Currency = { id: 6, name: 'KRW' }
export const USDT_TRC20: Currency = { id: 7, name: 'USDT-TRC20' }
export const USDT_BEP20: Currency = { id: 9, name: 'USDT-BEP20' }
export const allCurrencies = [VND, THB, RMB, IDR, MYR, KRW, USDT_TRC20, USDT_BEP20]

export interface OtpRequest {
  session: string
  otpReference: string
  transaction: string
  merchant: string
  customer: string
  fromAccountNumber: string
  toAccountNumber: string
  currency: string
  amount: number
}

export interface CaptchaRequest {
  session: string
  payload: string
  bankCode: string
}

export interface TransactionBundle {
  id: string
  merchantId: string
  customerId: string
  externalReference: string
  currency: Currency
  amount: number
  totalSplitted: number
  totalSuccessful: number
  splittedTransactions: Transaction[]
  dateCreated: Date
  dateDone: Date
}

export interface Transaction {
  id: string
  merchantId: string
  customerId: string
  fromAccount: string
  toAccount: string
  externalReference: string
  currency: Currency
  amount: number
  result: TransferResult
  type: {
    case: 'FundIn' | 'FundOut'
  }
  dateCreated: string
  dateDone: string
}

export interface dailyReportMerchant {
  agentId: number
  applicableTime: string
  depositFee: number
  merchant: string
  merchantId: number
  totalDeposit: number
  totalFee: number
  totalWithdrawal: number
  withdrawalFee: number
  currency: Currency
}

export interface dailyReportTotal {
  deposit: number
  withdraw: number
  totalDepositFee: number
  totalWithdrawFee: number
  currency: Currency
}

export interface TransferFailed {
  step: number
  stepName: string
  error: string
  errorDetail: string
}

export interface TransferSuccessful {
  bankReference: string
  endBalance: string
}

export type TransferResult =
  | {
    successful?: TransferSuccessful
    retryableFailed?: TransferFailed
    unretryableFailed?: TransferFailed
  }
  | 'pending'
  | 'inProgress'

export interface Money {
  currency: string
  amount: number
}

export interface SignIn {
  username: string
  password: string
}

export interface AuthenticatedUser {
  username: string
  accessToken: string
}

export interface OptionItem {
  id: number
  value: string
}

export interface Merchant {
  id: number
  name: string
  status: number
  statusText: string
  createdTime: string
}

export interface MonthlySummary {
  deposit: number
  withdraw: number
  fee: number
}
