import { Box, Card, Typography, Stack } from '@mui/material'
import * as React from 'react'

interface NumericCardProps {
  title: string
  subTitle?: string
  value?: number
  loading: boolean
  formatter?: (_: number) => string
}

export const NumericCard = (props: NumericCardProps): JSX.Element => {
  const { formatter = (x: number) => x.toString() } = props

  const title = props.subTitle != null
    ? (
      <Stack>
        <Typography variant='caption' fontWeight={600} color='text.secondary'>{props.title}</Typography>
        <Typography variant='caption' lineHeight={0.5} color='text.secondary'>{props.subTitle}</Typography>
      </Stack>
      )
    : (
      <Typography variant='caption' fontWeight={600} color='text.secondary'>{props.title}</Typography>
      )

  const value = props.value != null ? props.value : 0

  return (
    <Card
      // compact
      sx={{ p: 2 }}
    >
      <Box
        sx={{
          height: 70,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        {title}
        {
          !props.loading && <Typography fontSize='22px' fontWeight={600}>{formatter(value)}</Typography>
        }
      </Box>
      {/* <Card.Item>
          <Shimmer width='100%' isDataLoaded={!props.loading}>
          </Shimmer>
        </Card.Item> */}
    </Card>
  )
}
