import { InputID, InputToJSONProps, Value } from '@Components/FormInputs/interface'
import { atomFamily, RecoilState, RecoilValueReadOnly, selectorFamily, SerializableParam } from 'recoil'
import { FormIsValidParams, FormIsValidToJSONProps, FormPayloadParams, FormPayloadParamsHasToJSON, IFormPayload, InputAtomParams, IPagination } from './interface'

const _inputAtom = selectorFamily<Value, InputAtomParams>({
  key: 'Input',
  get: ({ defaultValue, location, id }) => ({ get }) =>
    get(formPayload({ key: location }))[id] ?? defaultValue
})
/**
 * @param location: useLocation,
 * @param id: InputID */
export const inputAtom = (params: InputToJSONProps): RecoilValueReadOnly<Value> => {
  const customizedParams = {
    ...params,
    toJSON: () => JSON.stringify({ location: params.location, id: params.id })
  }
  return _inputAtom(customizedParams)
}

const _formPayload = atomFamily<IFormPayload, FormPayloadParamsHasToJSON>({
  key: 'FormPayload',
  default: ({ defaultValue }) => defaultValue ?? {}
})

export const formPayload = (params: FormPayloadParams): RecoilState<IFormPayload> => {
  const customParams = {
    ...params,
    toJSON: () => params.key
  }
  return _formPayload(customParams)
}

const _formIsValidAtom = selectorFamily<boolean, FormIsValidToJSONProps>({
  key: 'formIsValidSelector',
  get: ({ location, validation }) => ({ get }) => {
    const data = get(formPayload({ key: location }))
    return validation?.safeParse(data).success ?? true
  }
})

export const formIsValidAtom = (params: FormIsValidParams): RecoilValueReadOnly<boolean> => {
  const customParams = {
    ...params,
    toJSON: () => params.location
  }
  return _formIsValidAtom(customParams)
}

export const paginationAtom = atomFamily<IPagination, string>({
  key: 'Pagination',
  default: { pageSize: 10, pageIndex: 1 }
})

// eslint-disable-next-line
export const payload = selectorFamily<any, { location: string, id?: InputID }>({
  key: 'payloadSetter',
  get: ({ location }) => ({ get }) => get(formPayload({ key: location })),
  set: ({ location, id }) => ({ set }, newValue: Value | IFormPayload) => {
    if ((typeof newValue !== 'object' || Array.isArray(newValue) || newValue === null) && id != null) {
      set(formPayload({ key: location }), (prev) => ({ ...prev, [id]: newValue }))
    } else if (typeof newValue === 'object' && !Array.isArray(newValue) && newValue != null) {
      set(formPayload({ key: location }), (prev) => ({ ...prev, ...newValue }))
    }
  }
})

interface ExternalLabelParams {
  defaultValue?: string | ((params: IFormPayload) => string) | undefined
  location: string
  id: string
  toJSON: () => SerializableParam
}
const _externalLabelAtom = selectorFamily<string | undefined, ExternalLabelParams>({
  key: 'InputExternalLabelAtom',
  get: ({ defaultValue, location }) => ({ get }) => {
    if (defaultValue == null || typeof defaultValue === 'string') return defaultValue
    const form = get(formPayload({ key: location }))
    return defaultValue(form)
  }
})

interface ExternalLabelAtomProps {
  defaultValue?: string | ((params: IFormPayload) => string) | undefined
  location: string
  id: string
}
export const externalLabelAtom = (params: ExternalLabelAtomProps): RecoilValueReadOnly<string | undefined> => {
  const { location, id } = params
  const toJSON = (): SerializableParam => `${location}${id}`
  return _externalLabelAtom({ toJSON, ...params })
}

export const errorMessageAtom = atomFamily<string[] | null, string>({
  key: 'InputerrorMessageAtom',
  default: null
})

export const triggerValidationAtom = atomFamily<boolean, string>({
  key: 'triggerValidationAtom',
  default: false
})
