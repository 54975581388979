import { localStorageEffect } from '@Utils/recoil'
import { atom, selector } from 'recoil'
import { IAuth } from './interface'
import { AuthenticatedUser } from '../Types'
import jwtDecode from 'jwt-decode'

export const authAtom = atom<IAuth>({
  key: 'auth',
  default: {
    authToken: null
  },
  effects: [
    localStorageEffect('auth')
  ]
})

interface AuthParams {
  user: AuthenticatedUser | undefined
  loggedIn: boolean
}
export const authParamsAtom = selector<AuthParams>({
  key: 'authParams',
  get: ({ get }) => {
    const auth = get(authAtom).authToken
    if (auth != null) {
      const decodedAuth: { 'unique_name': string } = jwtDecode(auth)
      const user = { accessToken: auth, username: decodedAuth.unique_name }
      return { user, loggedIn: true }
    }

    return { user: undefined, loggedIn: false }
  }
})
