import { Box, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { EnhancedTableProps } from './interface'
import React from 'react'

export const EnhancedTableHead = <T,>(props: EnhancedTableProps<T>): JSX.Element => {
  const { order, orderBy, onRequestSort, columns } = props
  const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {columns.map((column, i) => (
          <TableCell
            key={`column-${String(column.field)}-${i}`}
            align={column.headerAlign}
            sortDirection={orderBy === column.field ? order : false}
            width={column.width}
            data-field={column.headerName}
          >
            {
              column.sortable !== false
                ? (
                  <TableSortLabel
                    active={orderBy === column.field}
                    direction={orderBy === column.field ? order : 'asc'}
                    {...column.field !== 'action' ? { onClick: createSortHandler(column.field) } : {}}
                  >
                    {column.headerName}
                    {orderBy === column.field
                      ? (
                        <Box component='span' sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                        )
                      : null}
                  </TableSortLabel>)
                : column.headerName
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
