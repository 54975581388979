import { RecoilState, useResetRecoilState } from 'recoil'
import React from 'react'
const useResetOnMount = <T>(atom: RecoilState<T>): void => {
  const reset = useResetRecoilState(atom)
  React.useEffect(() => {
    return () => {
      reset()
    }
  }, [reset])
}

export default useResetOnMount
