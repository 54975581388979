import { errorMessageAtom, externalLabelAtom, formPayload, inputAtom, triggerValidationAtom } from '@RecoilGlobal/form'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { Paper, Box, InputLabel } from '@mui/material'
import React from 'react'
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil'
import { IInputProps } from './interface'
import { useResetOnMount, useSetForm, useStateChangeEffect } from '@Hooks'
import { ZodError } from 'zod'
import moment, { Moment } from 'moment'

interface DatePickerProps extends IInputProps {
  minDate?: Moment | undefined
  maxDate?: Moment | undefined
  format?: string
  views?: keyof typeof dateViews
  disableFuture?: boolean
}
const InputDatePicker = ({
  location,
  id,
  minDate,
  maxDate,
  views = 'MMMMYYYY',
  externalLabel: pExternalLabel,
  required = false,
  validation,
  disableFuture = false,
  onChange = async () => { }
}: DatePickerProps): JSX.Element => {
  const formValue = useRecoilValue(inputAtom({ location, id })) as string
  const value = moment(formValue)
  const setPayload = useSetForm({ location, id })
  const [errorMessage, setErrorMessage] = useRecoilState(errorMessageAtom(`${location}${id}`))
  const externalLabel = useRecoilValue(externalLabelAtom({ defaultValue: pExternalLabel, id, location }))
  const triggerValidation = useRecoilValue(triggerValidationAtom(location))

  useResetOnMount(errorMessageAtom(`${location}${id}`))
  useStateChangeEffect(() => {
    validationCB().finally(() => { })
  }, [triggerValidation])

  const validationCB = useRecoilCallback(({ snapshot }) => async () => {
    const form = await snapshot.getPromise(formPayload({ key: location }))
    try {
      validation?.parse(form)
      setErrorMessage(null)
    } catch (e) {
      if (!(e instanceof ZodError)) return
      const fieldError = e.flatten().fieldErrors[id]
      if (fieldError != null) {
        setErrorMessage(fieldError)
      } else {
        setErrorMessage(null)
      }
    }
  }, [])

  const handleBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = () => {
    if (validation == null) return
    validationCB().finally(() => { })
  }

  const handleOnChange = (value: Moment | null): void => {
    let newValue: string | null = null
    if (value != null) {
      newValue = moment(value).utc().toISOString()
    }
    setPayload(newValue)
    onChange(id, newValue).finally(() => { })
    if (errorMessage != null && validation != null) {
      validationCB().finally(() => { })
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box>
        {externalLabel != null &&
          <InputLabel
            sx={theme => ({
              textAlign: 'left',
              px: 1,
              color: errorMessage != null ? theme.palette.error.light : theme.palette.text.secondary
            })}
            htmlFor={id}
          >
            <strong>{externalLabel} {required && '*'}</strong>
          </InputLabel>}
        <Paper>
          <DatePicker
            value={value}
            minDate={minDate}
            maxDate={maxDate}
            views={dateViews[views]}
            disableFuture={disableFuture}
            slotProps={{
              textField: {
                sx: { width: '100%' },
                helperText: errorMessage,
                onBlur: handleBlur
              }
            }}
            onAccept={handleOnChange}
          />

        </Paper>
      </Box>
    </LocalizationProvider>
  )
}

export default InputDatePicker

export const dateViews = {
  MMMMYYYY: ['month', 'year']
} as const
