import { useStateChangeEffect } from '@Hooks'
import { searchResponseAtom } from '@RecoilGlobal/api'
import { Collapse, TableCell, TableRow } from '@mui/material'
import React from 'react'
import { atomFamily, useRecoilValue, useResetRecoilState } from 'recoil'

export const dataCollapseAtom = atomFamily({
  key: 'dataCollapseAtom',
  default: false
})

const CollapseItem = ({ location, rowIdx, children, colSpan }: { location: string, rowIdx: number, children: React.ReactNode, colSpan: number }): JSX.Element => {
  const collapseIn = useRecoilValue(dataCollapseAtom(`${location}${rowIdx}`))
  const searchData = useRecoilValue(searchResponseAtom(location))
  const reset = useResetRecoilState(dataCollapseAtom(`${location}${rowIdx}`))
  useStateChangeEffect(() => {
    reset()
  }, [searchData])
  return (
    <TableRow>
      <TableCell colSpan={colSpan} sx={{ py: 0, height: '0 !important' }}>
        <Collapse in={collapseIn}>
          {children}
        </Collapse>
      </TableCell>
    </TableRow>

  )
}

export default CollapseItem
