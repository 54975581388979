
import { Box, IconButton, Stack, useTheme, Chip, Typography, Avatar, useMediaQuery } from '@mui/material'
import React, { useRef } from 'react'
import MenuIcon from '@mui/icons-material/Menu'

import LogoSection from './LogoSection'

import { useRecoilValue, useSetRecoilState } from 'recoil'
import { leftDrawerOpenedAtom, openProfileAtom } from '../recoil'
import SettingsPopover from './Settings'
import { authParamsAtom } from '@RecoilGlobal/auth'
import { BrowserView, MobileView } from '@Components/layout/ViewPort'

const Header: React.FC = () => {
  const { user } = useRecoilValue(authParamsAtom)
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const setProfilePopoverOpen = useSetRecoilState(openProfileAtom)
  const setLeftDrawerOpen = useSetRecoilState(leftDrawerOpenedAtom)
  const profileRef = useRef<HTMLDivElement | null>(null)
  const profileMobileRef = useRef<HTMLDivElement | null>(null)
  const handleMenu = (): void => {
    setProfilePopoverOpen(true)
  }
  return (
    <>
      <Box
        sx={{
          width: 228,
          display: 'flex',
          flexGrow: 1,
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          },
          bgcolor: theme.palette.primary.main
        }}
      >
        <Stack
          direction='row'
          alignItems='center'
        >
          <IconButton
            size='large'
            edge='start'
            sx={{ mr: 2, color: theme.palette.primary.contrastText }}
            onClick={() => setLeftDrawerOpen(prev => !prev)}
          >
            <MenuIcon />
          </IconButton>
          <BrowserView>
            <Box component='span' sx={{ flexGrow: 1 }}>
              <LogoSection />
            </Box>
          </BrowserView>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <BrowserView>
          <Chip
            ref={profileRef}
            sx={{
              p: 1,
              height: '48px',
              alignItems: 'center',
              borderRadius: '27px',
              transition: 'all .2s ease-in-out',
              borderColor: theme.palette.primary.main,
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              '&[aria-controls="menu-list-grow"], &:hover': {
                borderColor: theme.palette.primary.dark,
                background: `${theme.palette.primary.dark}!important`
              },
              '& .MuiChip-label': {
                lineHeight: 0
              }
            }}
            icon={
              <Avatar
                src='./logo.svg'
                alt={user?.username}
                sx={{
                  width: '34px',
                  height: '34px',
                  fontSize: '1.2rem',
                  margin: '8px 0 8px 8px !important',
                  cursor: 'pointer'
                }}
              />
            }
            label={<Typography>{user?.username}</Typography>}
            variant='outlined'
            onClick={handleMenu}
            color='primary'
          />
        </BrowserView>
        <MobileView>
          <Avatar
            src='./logo.svg'
            alt={user?.username}
            sx={{
              width: '34px',
              height: '34px',
              fontSize: '1.2rem',
              margin: '8px 0 8px 8px !important',
              cursor: 'pointer'
            }}
            ref={profileMobileRef}
            onClick={handleMenu}
          />
        </MobileView>
      </Box>
      <SettingsPopover anchorRef={isMdUp ? profileRef : profileMobileRef} />
    </>
  )
}

export default Header
