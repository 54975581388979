import * as React from 'react'
import { Navigate } from 'react-router-dom'
import { Layout } from '../layout/Layout'
import { useRecoilValue } from 'recoil'
import { authParamsAtom } from '@RecoilGlobal/auth'

interface IAuthenticatedRouteProps {
  loginPath?: string
  children: JSX.Element
}

const AuthenticatedRoute = ({
  children,
  loginPath
}: IAuthenticatedRouteProps): JSX.Element => {
  const path = loginPath != null ? loginPath : '/login'
  const { loggedIn } = useRecoilValue(authParamsAtom)
  return (
    loggedIn ? <Layout>{children}</Layout> : <Navigate to={path} />
  )
}

export default AuthenticatedRoute
