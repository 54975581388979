import React from 'react'
import moment from 'moment'
import InputDatePicker from '@Components/FormInputs/InputDatePicker'
import { InputID } from '@Components/FormInputs/interface'
import InputSelect from '@Components/FormInputs/InputSelect'
import { Grid } from '@mui/material'
import { Merchant, allCurrencies } from '../../Types'

export interface ICommandsBarProps {
  location: string
  merchants: Merchant[]
}

export const CommandsBar = ({ location, merchants = [] }: ICommandsBarProps): JSX.Element => {
  const merchantOptions = [
    { value: -1, label: 'All' },
    ...merchants.map(item => ({
      value: item.id, label: item.name
    }))
  ]
  return (
    <Grid component='form' container spacing={2}>
      <Grid item xs={12} md={6} lg={3}>
        <InputDatePicker
          id={InputID.startDate}
          location={location}
          minDate={moment().subtract(6, 'months').startOf('month')}
          disableFuture
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <InputSelect
          id={InputID.currency}
          location={location}
          options={allCurrencies.map(x => {
            return { value: x.id, label: x.name }
          })}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <InputSelect
          id={InputID.merchantId}
          location={location}
          options={merchantOptions}
        />
      </Grid>
    </Grid>
    // <OverflowSet
    //   styles={{
    //     root: {
    //       backgroundColor: 'white',
    //       height: '30px;',
    //       padding: '10px',
    //     },
    //   }}
    //   items={[dateRange, selectCurrency, selectMerchant]}
    //   onRenderItem={onRenderItem}
    //   onRenderOverflowButton={() => <div />}
    // />
  )
}
