import React from 'react'
import { useRecoilValue } from 'recoil'
import { errorMessageAtom } from '@RecoilGlobal/form'
import { Typography } from '@mui/material'
import { Alert } from '@mui/lab'
interface Props {
  location: string
}

const ErrorFieldMessage: React.FC<Props> = ({ location }) => {
  const errorMessage = useRecoilValue(errorMessageAtom(location))

  return (
    <>
      {errorMessage != null &&
        <Alert
          severity='error'
        >
          <Typography
            sx={theme => ({ color: theme.palette.error.main })}
            variant='caption'
          >
            {errorMessage}
          </Typography>
        </Alert>}
    </>
  )
}

export default ErrorFieldMessage
