import { IFormPayload } from '@RecoilGlobal/interface'
import { Moment } from 'moment'
import { z } from 'zod'
export type Value = string | number | boolean | number[] | Array<number | string> | null | undefined | Moment

export interface InputToJSONProps {
  location: string
  id: InputID
  defaultValue?: Value
}

export interface IInputProps extends InputToJSONProps {
  error?: boolean
  errorMessage?: string
  label?: string
  type?: string
  required?: boolean
  disabled?: boolean
  readOnly?: boolean
  borderRadius?: string
  placeholder?: string
  externalLabel?: string | ((params: IFormPayload) => string)
  helperText?: string
  validation?: z.AnyZodObject | z.ZodEffects<z.AnyZodObject>
  onChange?: (id: InputID, value: Value) => Promise<void>
}

export interface Options {
  value: number | string
  label: string
}

export enum InputID {
  startDate = 'startDate',
  endDate = 'endDate',
  currency = 'currency',
  merchantId = 'merchantId',
  username = 'username',
  password = 'password',
  OldPassword = 'OldPassword',
  NewPassword = 'NewPassword',
  ConfirmPassword = 'ConfirmPassword'
}
