import { InputID, Value } from '@Components/FormInputs/interface'
import { payload } from '@RecoilGlobal/form'
import { IFormPayload } from '@RecoilGlobal/interface'
import { useRecoilCallback } from 'recoil'

interface IUseSetForm {
  location: string
  id: InputID
}
export const useSetForm = ({ location, id }: IUseSetForm): (newValue: Value) => void => {
  const callback = useRecoilCallback(({ set }) => (newValue: Value | IFormPayload) => {
    set(payload({ location, id }), newValue)
  })
  return callback
}
