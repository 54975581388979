import { useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

const BrowserView: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const theme = useTheme()
  const match = useMediaQuery(theme.breakpoints.up('md'))
  return (
    <>
      {match && children}
    </>
  )
}

const MobileView: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const theme = useTheme()
  const match = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <>
      {match && children}
    </>
  )
}

export { BrowserView, MobileView }
