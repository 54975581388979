import { useLocation } from 'react-router-dom'
import { ListItemButton, ListItemIcon, ListItemText, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { MenuList } from './Menu'
import { renderLink } from '@Utils/router'

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

interface INavItem {
  item: MenuList
  level: number
}

const MenuItem: React.FC<INavItem> = ({
  item: { icon: Icon, ...item },
  level
}) => {
  const theme = useTheme()
  const [isSelected, setIsSelected] = useState(false)
  const location = useLocation()

  useEffect(() => {
    if (location.pathname === item.path) {
      setIsSelected(true)
    } else {
      setIsSelected(false)
    }
  }, [location, item])

  return (
    <ListItemButton
      component={renderLink(item.path)}
      disableRipple
      sx={{
        borderRadius: '10px',
        mb: 0.5,
        alignItems: 'center',
        backgroundColor: isSelected ? `${theme.palette.primary.light} !important` : 'inherit',
        py: level > 1 ? 0.5 : 0.75,
        ml: `${level > 1 ? level * 10 : 0}px`
      }}
      selected={isSelected}
    >
      <ListItemIcon sx={{ my: 'auto', minWidth: 36 }}>
        <Icon
          sx={{
            fontSize: '18px'
          }}
          color='primary'
        />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            variant='body2'
            color='inherit'
            sx={{
              color: isSelected ? theme.palette.primary.main : theme.palette.text.primary,
              fontWeight: isSelected ? 'bold' : 'normal'
            }}
          >
            {item.title}
          </Typography>
        }
      />
    </ListItemButton>
  )
}

export default MenuItem
