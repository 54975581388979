import { useLayoutEffect } from 'react'
import api from '@Utils/axios'
import { useLogout } from './useLogout'
export const useMiddleware = (): void => {
  const logout = useLogout()
  useLayoutEffect(() => {
    const interceptor = api.interceptors.response.use(response => response, async function (error) {
      if (error.response == null || error.response.status === 401) {
        logout()
      }
      return await Promise.reject(error)
    })

    return () => {
      api.interceptors.response.eject(interceptor)
    }
  }, [logout])
}
