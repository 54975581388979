import { Typography, styled, TextField } from '@mui/material'

export const handleHelperText = (props: { errorMessage: string[] | null, helperText: string | undefined }): React.ReactNode => {
  const { errorMessage, helperText } = props
  if (errorMessage == null) return helperText
  return (
    <>
      {helperText}
      {errorMessage?.map((text, idx) => (
        <Typography key={idx} variant='caption'>{text}</Typography>
      ))}
    </>
  )
}

export const StyledTextField = styled(TextField)(({ error }: { error: boolean }) => ({
  input: {
    '&::placeholder': {
      color: error ? 'red' : 'grey'
    }
  }
}))
