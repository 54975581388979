import { Order } from './interface'

function descendingComparator<T> (a: T, b: T, orderBy: keyof T | undefined): number {
  if (orderBy == null) return 0
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}
function getComparator<T> (
  order: Order,
  orderBy: keyof T | undefined
): (
    a: T,
    b: T,
  ) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T> (array: readonly T[], comparator: (a: T, b: T) => number): T[] {
  const stabilizedThis: Array<[T, number]> = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export {
  getComparator,
  stableSort
}
