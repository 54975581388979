import { z } from 'zod'

// Website (reference: https://www.regextester.com/93652)
export const websiteRegEx = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
export const decimalRegEx = /^\d*(\.)?(\d*)?$/
export const ipAddressRegEx = /^(\d{1,3}\.?|)*$/

export const Z_STRING_FIELD_REQUIRED = z.string().min(1, 'field required')
export const DECIMAL_NUMBER = z.string().regex(decimalRegEx)
export const STRING_NUMBER = z.string().regex(/^[0-9]*$/)
export const STRING_IPADDRESS = z.string().regex(ipAddressRegEx)
export const Z_STRING_IPADRESS_REQUIRED = z.string().regex(ipAddressRegEx, 'please enter a valid IP Address').min(1, 'field required')
export const Z_DECIMAL_PERCENTAGE = z.string().min(1, 'field required').regex(decimalRegEx, '- must be a number')
  .transform(val => parseInt(val)).pipe(z.number().max(100, '- must be less than 100 percent'))
export const Z_NUMBER_NON_ZERO_REQUIRED = z.number().min(1, 'field required')
export const Z_NUMBER_REQUIRED = z.number().min(0, 'field required')
export const Z_STRING_NUMBER_REQUIRED = z.string().regex(/^[0-9]*$/).min(1, 'field required')
