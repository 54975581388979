import React from 'react'
import { Drawer, Box, useTheme, useMediaQuery, Divider, SvgIconTypeMap } from '@mui/material'
import MenuItem from './MenuItem'
import { useRecoilState } from 'recoil'
import { leftDrawerOpenedAtom } from '@Components/layout/recoil'
import { MobileView } from '@Components/layout/ViewPort'
import LogoSection from '@Components/layout/header/LogoSection'
// import DashboardIcon from '@mui/icons-material/Dashboard'
import SummarizeIcon from '@mui/icons-material/Summarize'
import BarChartIcon from '@mui/icons-material/BarChart'
import { OverridableComponent } from '@mui/material/OverridableComponent'

const Menu: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useRecoilState(leftDrawerOpenedAtom)
  // const container = window !== undefined ? () => window.document.body : undefined
  const theme = useTheme()
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))

  const handleDrawerToggle = (): void => {
    setDrawerOpen(prev => !prev)
  }
  return (
    <Box component='nav' sx={{ flexShrink: { md: 0 }, width: matchUpMd ? 300 : 'auto' }} aria-label='mailbox folders'>
      <Drawer
        // container={container}
        variant={matchUpMd ? 'persistent' : 'temporary'}
        anchor='left'
        open={drawerOpen}
        onClose={handleDrawerToggle}
        elevation={4}
        sx={{
          '& .MuiDrawer-paper': {
            width: 300,
            background: theme.palette.background.paper,
            borderRight: 'none',
            [theme.breakpoints.up('md')]: {
              top: '65px'
            }
          }

        }}
        ModalProps={{ keepMounted: true }}
        color='inherit'
      >
        <Box
          sx={{
            height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
            paddingLeft: '16px',
            paddingRight: '16px',
            marginTop: '8px',
            backgroundColor: theme.palette.background.paper,
            borderRadius: '20px'
          }}
        >
          <MobileView>
            <Box sx={{ my: 2 }}>
              <LogoSection />
            </Box>
            <Divider />

          </MobileView>
          {
            menuList.map((item, idx) => (
              <React.Fragment key={idx}>
                <MenuItem item={item} level={1} />
              </React.Fragment>
            ))
          }
        </Box>
      </Drawer>
    </Box>
  )
}

export default Menu

export interface MenuList {
  path: string
  title: string
  icon: OverridableComponent<SvgIconTypeMap<object, 'svg'>>
}

const menuList: MenuList[] = [
  // {
  //   title: 'Dashboard',
  //   path: '/dashboard',
  //   icon: DashboardIcon
  // },
  {
    title: 'Summary',
    path: '/summary',
    icon: BarChartIcon
  },
  {
    title: 'Daily Report',
    path: '/daily-report',
    icon: SummarizeIcon
  }
]
