export function copyAndSort<T> (
  items: T[],
  columnKey: string,
  isSortedDescending = false
): T[] {
  const key = columnKey as keyof T
  return items
    .slice(0)
    .sort((a: T, b: T) =>
      (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
    )
}

export const defaultCurrencyFormatter = (num: number): string => `USD ${num.toLocaleString('en-US')}`

export const currencyFormatters: {
  [currency: string]: (num: number) => string
} = {
  USD: defaultCurrencyFormatter,
  VND: (num: number): string => `VND ${num.toLocaleString('en-US')}`,
  THB: (num: number): string => `THB ${num.toLocaleString('en-US')}`,
  CNY: (num: number): string => `CNY ${num.toLocaleString('en-US')}`,
  RMB: (num: number): string => `CNY ${num.toLocaleString('en-US')}`,
  IDR: (num: number): string => `IDR ${num.toLocaleString('en-US')}`,
  MYR: (num: number): string => `MYR ${num.toLocaleString('en-US')}`,
  KRW: (num: number): string => `KRW ${num.toLocaleString('en-US')}`,
  'USDT-TRC20': (num: number): string => `USDT ${num.toLocaleString('en-US')}`,
  'USDT-BEP20': (num: number): string => `USDT ${num.toLocaleString('en-US')}`
}

export const formatByCurrency = (ccy: string | null) => (x: number) => {
  if (ccy == null) return defaultCurrencyFormatter(x)
  if (ccy in currencyFormatters) {
    return currencyFormatters[ccy as keyof typeof currencyFormatters](x)
  }
  return defaultCurrencyFormatter(x)
}

export const decimalFormatter = new Intl.NumberFormat('en-US')

export const decimalFormat = (x: number): string => decimalFormatter.format(x)

const currencies = [
  'USD', // as default
  'VND',
  'THB',
  'RMB',
  'IDR',
  'MYR',
  'KRW'
]
export const getCurrencyText = (idx: number): string => currencies[idx] ?? currencies[0]

export const clientTimeZone = -(new Date().getTimezoneOffset() / 60)
