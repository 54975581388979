import 'react-app-polyfill/ie9'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './App'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'

// mergeStyles({
//   selectors: {
//     ":global(body), :global(html), :global(#root)": {
//       margin: 0,
//       padding: 0,
//       height: "100vh",
//     },
//   },
// });

// const customizations = FluentCustomizations; // or alternatively AzureCustomizationsLight or FluentCustomizations
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <RecoilRoot>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </RecoilRoot>
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
