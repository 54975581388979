import React from 'react'
import { IDataTable } from './interface'
import Pagination from './Pagination'
import EnhancedTable from './Table'

export const DataTable = <T,>({ columns, location, loading, onSearch, disablePagination = false, data, collapse }: IDataTable<T>): JSX.Element => (
  <>
    {!disablePagination && <Pagination<T> location={location} onSearch={onSearch} />}
    {/* <StyledDataGrid
        sx={{
          [theme.breakpoints.down('md')]: {
            height: 'calc(100vh - 180px)'
          }
        }}
        autoHeight={matchUpMd}
        disableColumnMenu
        rows={rows}
        page={pagination.page}
        columns={customizedColumns}
        pageSize={pagination.pageSize}
        hideFooter
        filterMode='server'
      /> */}
    <EnhancedTable<T> location={location} columns={columns} loading={loading} data={data} collapse={collapse} />
  </>
)
