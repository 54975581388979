import { LoadingButton } from '@mui/lab'
import { formIsValidAtom } from '@RecoilGlobal/form'
import React from 'react'
import { useRecoilValue } from 'recoil'
import { z } from 'zod'
import { AnyZodObject } from 'zod/lib/types'

interface ButtonSubmitProps {
  label?: string
  location: string
  validation?: AnyZodObject | z.ZodEffects<AnyZodObject>
  disabled?: boolean
  onSubmit?: React.FormEventHandler<HTMLButtonElement>
  loading?: boolean
}

const ButtonSubmit = ({
  label = 'Submit',
  location,
  validation,
  disabled,
  onSubmit,
  loading = false
}: ButtonSubmitProps): JSX.Element => {
  const formIsValid = useRecoilValue(formIsValidAtom({ location, validation }))

  return (
    <LoadingButton
      loading={loading}
      onSubmit={onSubmit}
      variant='contained'
      color='primary'
      type='submit'
      disabled={!formIsValid || disabled}
      sx={{
        width: '100%'
      }}
    >
      {label}
    </LoadingButton>
  )
}

export default ButtonSubmit
