import { FormControl, InputLabel, Box } from '@mui/material'
import React from 'react'
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil'
import { inputAtom, errorMessageAtom, externalLabelAtom, formPayload, triggerValidationAtom } from '@RecoilGlobal/form'
import { IInputProps } from './interface'
import { useResetOnMount, useSetForm, useStateChangeEffect } from '@Hooks'
import { z, ZodError } from 'zod'
import { handleHelperText, StyledTextField } from './utils'

interface IInputText extends IInputProps {
  autoFocus?: boolean
  multiline?: boolean
  regex?: z.ZodString
}

const InputText = ({
  id,
  location,
  label,
  type = 'text',
  borderRadius = '4px',
  placeholder,
  readOnly,
  externalLabel: pExternalLabel,
  disabled,
  required = false,
  defaultValue = '',
  validation,
  helperText,
  regex,
  onChange = async () => {},
  autoFocus,
  ...rest
}: IInputText): JSX.Element => {
  const value = useRecoilValue(inputAtom({ location, id, defaultValue }))
  const setPayload = useSetForm({ location, id })
  const isReadonlyOrDisabled = Boolean(readOnly) || Boolean(disabled)
  const [errorMessage, setErrorMessage] = useRecoilState(errorMessageAtom(`${location}${id}`))
  const externalLabel = useRecoilValue(externalLabelAtom({ defaultValue: pExternalLabel, id, location }))
  const triggerValidation = useRecoilValue(triggerValidationAtom(location))

  useResetOnMount(errorMessageAtom(`${location}${id}`))

  useStateChangeEffect(() => {
    validationCB().finally(() => {})
  }, [triggerValidation])

  const validationCB = useRecoilCallback(({ snapshot }) => async () => {
    const form = await snapshot.getPromise(formPayload({ key: location }))
    try {
      validation?.parse(form)
      setErrorMessage(null)
    } catch (e) {
      if (!(e instanceof ZodError)) return
      const fieldError = e.flatten().fieldErrors[id]
      if (fieldError != null) {
        setErrorMessage(fieldError)
      } else {
        setErrorMessage(null)
      }
    }
  }, [])
  const handleBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = () => {
    if (validation == null) return
    validationCB().finally(() => { })
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault()
    const newValue = e.target.value
    if (regex == null || regex.safeParse(newValue).success) {
      setPayload(newValue)
      onChange(id, newValue).finally(() => {})
    }
    if (errorMessage != null && validation != null) {
      validationCB().finally(() => { })
    }
  }

  return (
    <Box>
      {externalLabel != null &&
        <InputLabel
          sx={theme => ({
            textAlign: 'left',
            px: 1,
            color: errorMessage != null ? theme.palette.error.light : theme.palette.text.secondary
          })}
          htmlFor={id}
        >
          <strong>{externalLabel} {required && '*'}</strong>
        </InputLabel>}
      <FormControl
        sx={{ width: '100%' }}
      >
        <StyledTextField
          {...rest}
          variant='outlined'
          size='small'
          fullWidth
          autoFocus={autoFocus}
          error={errorMessage != null}
          helperText={handleHelperText({ errorMessage, helperText })}
          required={required}
          label={label}
          name={id}
          type={type}
          value={value}
          onBlur={handleBlur}
          onChange={handleOnChange}
          InputProps={{
            sx: theme => ({
              backgroundColor: isReadonlyOrDisabled ? theme.palette.background.default : theme.palette.background.paper,
              boxShadow: theme.shadows[2],
              borderRadius
            }),
            style: { borderRadius },
            readOnly,
            disabled,
            placeholder,
            required
          }}
          FormHelperTextProps={{
            sx: {
              position: 'relative',
              left: -10,
              display: 'flex',
              flexDirection: 'column'
            }
          }}
        />
      </FormControl>
    </Box>
  )
}

export default InputText
