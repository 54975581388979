import { loadingAtom, searchResponseAtom } from '@RecoilGlobal/api'
import { errorMessageAtom, formPayload } from '@RecoilGlobal/form'
import { AxiosResponse } from 'axios'
import moment, { Moment } from 'moment'
import { useRecoilCallback, useRecoilState } from 'recoil'
import { TotalReportResponse } from './interface'
import { clientTimeZone, getCurrencyText } from '@Utils'
import api from '@Utils/axios'

export const useGetSearchReport = (location: string): { searchReport: () => Promise<void>, loading: boolean } => {
  const [loading, setLoading] = useRecoilState(loadingAtom('useGetSearchReport'))
  const cb = useRecoilCallback(({ snapshot, set }) => async () => {
    setLoading(true)
    const { startDate, endDate, merchantId, currency } = await snapshot.getPromise(formPayload({ key: location })) as { startDate: Moment | null, endDate: Moment | null, currency: number, merchantId: number }
    const startDateISOFormat = startDate != null ? moment(startDate).startOf('day').utc(true).toISOString() : null
    const endDateISOFormat = endDate != null ? moment(endDate).endOf('day').utc(true).toISOString() : null
    const firstDayOfMonth = moment().startOf('month').utc(true).toISOString()
    const lastDayOfMonth = moment().endOf('month').utc(true).toISOString()
    try {
      const responseMainReport = await api.get(`/AgentTransactions/GetTransactionSummaryPerMechant?startDate=${startDateISOFormat === null ? firstDayOfMonth : startDateISOFormat}&endDate=${endDateISOFormat === null ? lastDayOfMonth : endDateISOFormat}&currency=${currency}&merchantId=${merchantId}&clientTimeZone=${clientTimeZone}`)
      const responseTotalReport: AxiosResponse<TotalReportResponse> = await api.get(`/AgentTransactions/GetDailyTransactionSummary?startDate=${startDateISOFormat === null ? firstDayOfMonth : startDateISOFormat}&endDate=${endDateISOFormat === null ? lastDayOfMonth : endDateISOFormat}&currency=${currency}&merchantId=${merchantId}&clientTimeZone=${clientTimeZone}`)
      if ('hasError' in responseTotalReport.data) {
        set(errorMessageAtom(location), [responseTotalReport.data.message])
      } else {
        const { deposit, depositFee, withdraw, withdrawalFee } = responseTotalReport.data
        const totalReport = {
          totalDeposit: deposit,
          depositFee,
          withdrawalFee,
          totalWithdrawal: withdraw
        }
        set(searchResponseAtom(location), { total: responseMainReport.data.length, data: [...responseMainReport.data, { ...totalReport, currency: getCurrencyText(currency) }] })
        set(errorMessageAtom(location), null)
      }
    } catch (e) {
      set(errorMessageAtom(location), ['Error'])
      set(searchResponseAtom(location), { total: 0, data: [] })
    }
    setLoading(false)
  })

  return { searchReport: cb, loading }
}

export const useGetDailyTransaction = (location: string): { getDailyTransaction: (merchantId: number) => Promise<void>, loading: boolean } => {
  const [loading, setLoading] = useRecoilState(loadingAtom('useGetDailyTransaction'))
  const cb = useRecoilCallback(({ snapshot, set }) => async (merchantId: number) => {
    const collapseLocation = `${location}/${merchantId}`
    setLoading(true)
    const { startDate, endDate, currency } = await snapshot.getPromise(formPayload({ key: location })) as { startDate: Moment | null, endDate: Moment | null, currency: number, merchantId: number }
    const startDateISOFormat = startDate != null ? moment(startDate).startOf('day').utc(true).toISOString() : null
    const endDateISOFormat = endDate != null ? moment(endDate).endOf('day').utc(true).toISOString() : null
    const firstDayOfMonth = moment().startOf('month').utc(true).toISOString()
    const lastDayOfMonth = moment().endOf('month').utc(true).toISOString()
    try {
      const res = await api({
        method: 'get',
        url: `/AgentTransactions/GetDailyTransaction?startDate=${startDateISOFormat === null ? firstDayOfMonth : startDateISOFormat}&endDate=${endDateISOFormat === null ? lastDayOfMonth : endDateISOFormat}&merchantId=${merchantId}&currency=${currency}&clientTimeZone=${clientTimeZone}`
      })
      set(searchResponseAtom(collapseLocation), { total: res.data.length, data: res.data })
    } catch (e) {
      set(errorMessageAtom(location), ['Error'])
      set(searchResponseAtom(location), { total: 0, data: [] })
    }
    setLoading(false)
  })
  return { getDailyTransaction: cb, loading }
}
