import { Card, Stack, Typography, Grid } from '@mui/material'
import InputText from '@Components/FormInputs/InputText'
import { InputID } from '@Components/FormInputs/interface'
import { useLocation } from 'react-router-dom'
import { z } from 'zod'
import { Z_STRING_FIELD_REQUIRED } from '@Utils/zod'
import FormContainer from '@Components/Containers/FormContainer'
import ButtonSubmit from '@Components/FormInputs/ButtonSubmit'
import { useLoginApi } from './api'
import ErrorFieldMessage from '@Components/error-field-message/ErrorFieldMessage'
import { useResetOnMount } from '@Hooks'
import { errorMessageAtom } from '@RecoilGlobal/form'
import { theme } from '@Utils/theme'

const Copyright = (): JSX.Element => (
  <Typography variant='caption'>
    © {theme.title} {new Date().getFullYear()}
  </Typography>
)

const formSchema = z.object({
  username: Z_STRING_FIELD_REQUIRED,
  password: Z_STRING_FIELD_REQUIRED
})
export const Login = (): JSX.Element => {
  const { pathname: location } = useLocation()
  const { postLogin, loading } = useLoginApi()

  useResetOnMount(errorMessageAtom(location))
  const handleSubmit = (): void => {
    postLogin().finally(() => {})
  }

  return (
    <Stack
      alignItems='center'
      justifyContent='center'
      spacing={3}
      m={0}
      minHeight='100vh'
    >

      <Typography variant='h5'>
        Back Office
      </Typography>
      <Card
        sx={{
          p: 5,
          width: 400,
          backgroundColor: '#fff'
        }}
      >

        <FormContainer
          onSubmit={handleSubmit}
        >
          <Grid item xs={12}>
            <ErrorFieldMessage location={location} />
          </Grid>

          <Grid item xs={12}>
            <InputText
              id={InputID.username}
              location={location}
              label='Username'
              validation={formSchema}
              autoFocus
            />
          </Grid>
          <Grid item xs={12}>
            <InputText
              id={InputID.password}
              location={location}
              label='Password'
              type='password'
              validation={formSchema}
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonSubmit
              loading={loading}
              location={location}
              label='Login'
            />
          </Grid>
        </FormContainer>
      </Card>
      <Copyright />
    </Stack>
  )
}
