import { createTheme } from '@mui/material'
import { blue, grey, green } from '@mui/material/colors'
import GameWalletLogo from '../assets/logo/GamewalletLogo.png'
import SRPayLogo from '../assets/logo/SRPAY_LOGO.png'

const GWtheme = createTheme({
  palette: {
    primary: {
      main: green[600],
      light: green[50],
      contrastText: '#fff'
    },
    background: {
      paper: '#fff',
      default: grey[100]
    }
  }
})

const SRPayTheme = createTheme({
  palette: {
    primary: {
      main: blue[500],
      light: blue[50]
    },
    background: {
      paper: '#fff',
      default: grey[100]
    }
  }
})

const SRPay = {
  customTheme: SRPayTheme,
  title: 'SRPay',
  logo: SRPayLogo
} as const

const GW = {
  customTheme: GWtheme,
  title: 'Gamewallet',
  logo: GameWalletLogo
} as const

const isGWorSRPay = window.location.hostname.toLowerCase().includes('srpaygateway')
export const theme = isGWorSRPay ? SRPay : GW
