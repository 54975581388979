import { Box, Paper, styled, Table, TableBody, TableCell, TableContainer, TableRow, Theme, Typography } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress/LinearProgress'
import { searchResponseAtom } from '@RecoilGlobal'
import React, { Fragment, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { IEnhancedTable, Order, RenderCellParams } from './interface'
import { EnhancedTableHead } from './TableHeader'
import { getComparator, stableSort } from './utils'
import CollapseItem from './CollapseItem'

const StyledTableContainer = styled(TableContainer)(({ theme }: { theme: Theme }) => ({
  [theme.breakpoints.down('md')]: {
    maxHeight: 'calc(100vh - 175px)',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  '.MuiTable-root': {
    WebkitFontSmoothing: 'auto',
    letterSpacing: 'normal',
    tableLayout: 'auto',
    minWidth: '100%'
  },
  '[data-field="Action"]': {
    position: 'sticky',
    right: 0,
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`
  },
  '.MuiTableCell-body[data-field="Action"]': {
    backgroundColor: theme.palette.background.paper
  },
  '.MuiTableCell-body': {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
    // minHeight: '61px'
  },
  '.MuiTableCell-root': {
    height: '61px'
  },
  '.MuiTableBody-root': {
    maxHeight: 'calc(100vh - 122px)',
    overflow: 'auto'
  }
}))

const EnhancedTable = <T,>({
  columns,
  loading,
  location,
  collapse,
  data
}: IEnhancedTable<T>): JSX.Element => {
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof T | undefined>(undefined)
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof T
  ): void => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  const searchData = useRecoilValue(searchResponseAtom<T>(location))
  const rows = data != null ? data : searchData.data
  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <StyledTableContainer>
          <Table
            stickyHeader
            aria-labelledby='tableTitle'
            size='small'
          >
            <EnhancedTableHead<T>
              columns={columns}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody
              sx={{ position: 'relative' }}
            >
              {stableSort<T>(rows, getComparator(order, orderBy))
                .map((row, rowIdx) => (
                  <Fragment key={`row-${rowIdx}`}>
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={`row-${rowIdx}`}
                    >
                      {columns.map((column, colIdx) => {
                        const key = `${rowIdx}${colIdx}`
                        const params: RenderCellParams<T> = {
                          row,
                          rowIdx,
                          id: key,
                          value: column.field !== 'action' ? row[column.field] : undefined
                        }
                        return (
                          <TableCell
                            key={key}
                            width={column.width}
                            data-field={column.headerName}
                          >
                            {column.renderCell != null
                              ? column.renderCell(params)
                              : column.field !== 'action' ? <>{row[column.field]}</> : <></>}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                    {
                      collapse != null && (
                        <CollapseItem location={location} rowIdx={rowIdx} colSpan={columns.length}>
                          {collapse(row)}
                        </CollapseItem>
                      )
                    }
                  </Fragment>
                ))}
              {rows.length === 0 &&
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                  >
                    <Typography
                      sx={theme => ({
                        color: theme.palette.text.secondary,
                        py: 4,
                        width: '100%',
                        textAlign: 'center'
                      })}
                    >
                      No data available
                    </Typography>
                  </TableCell>
                </TableRow>}
              {loading &&
                <tr>
                  <Box
                    component='td'
                    sx={theme => ({
                      color: theme.palette.text.secondary,
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      background: 'linear-gradient(180deg, rgba(255,255,255,0.7) 0%, rgba(255,255,255,1) 100%);rgba(255, 255, 255, 0.6)',
                      left: 0,
                      top: 0
                    })}
                  >
                    <LinearProgress />
                  </Box>
                </tr>}
            </TableBody>

          </Table>
        </StyledTableContainer>
      </Paper>
    </Box>
  )
}

export default EnhancedTable
